import React, { useState, useEffect } from 'react';
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby"

import Seo from "../../components/seo"
import Layout from "../../components/layout"
import Article from "../../components/article"
import LanguageBar from "../../components/languageBar"

import useWindowSize from "../../utilt/useWindowSize"

//CSS
import { article } from "./connected.module.css"
import { titleAndButts } from "./connected.module.css"
import { eachTitle } from "./connected.module.css"
import { desc } from "./connected.module.css"
import { buttonsDiv } from "./connected.module.css"
import { fullScreenButtDiv } from "./connected.module.css"
import { button } from "./connected.module.css"
import { fullScreenButt } from "./connected.module.css"
import { buttons } from "./connected.module.css"
import { sumbNails } from "./connected.module.css"
import { sumbNail } from "./connected.module.css"
import { sumbNailCur } from "./connected.module.css"
import { modalArea } from "./connected.module.css"
import { isShowCSS } from "./connected.module.css"
import { modalBg } from "./connected.module.css"
import { modalWrapper } from "./connected.module.css"
import { imgHide } from "./connected.module.css"
import { modalContentCSS } from "./connected.module.css"
import { modalContentScale } from "./connected.module.css"
import { close } from "./connected.module.css"

import getLanguage from "../../utilt/getLangueage"
import calcModalSize from "../../utilt/calcModalSize"
import getIsTouchscreen from "../../utilt/getIsTouchscreen"
//import seoImg from "../../images/seo/seo_connected.jpg"

let isTouch = getIsTouchscreen();


/**
 * ページ
 */
const Connected = (props) => {
  useEffect(() => {
    setTimeout(() => {
      isTouch = getIsTouchscreen();
    }, 0)
  }, [])

  const [lang, setLang] = useState(getLanguage());
  function setLanguage(enOrJa) {
    setLang(enOrJa);
  }
  const winSize = useWindowSize() || props.size || { width: 640, height: 948 }


  //画像データ取得
  const data = useStaticQuery(graphql`query QueryConnected {
  allFile(
    filter: {extension: {regex: "/(jpg)/"}, relativeDirectory: {eq: "connected"}}
  ) {
    edges {
      node {
        base
        childImageSharp {
          gatsbyImageData(quality: 50, placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
        }
      }
    }
  }
}
`)


  //1 取得した画像データを配列化
  const imagesOld = data.allFile.edges.map(n => n.node);
  const images = imagesOld.slice();

  //2 ファイル名で並べ替え
  function compare(a, b) {
    const baseA = a.base;
    const baseB = b.base;

    let comparison = 0;
    if (baseA > baseB) {
      comparison = 1;
    } else if (baseA < baseB) {
      comparison = -1;
    }
    return comparison;
  }
  images.sort(compare);

  //3 配列を二分してサムネイルとモーダル表示用画像を分ける
  const ImagesLength = Math.round(images.length / 2) //中央が総数になる
  const allImages = images.slice(0, ImagesLength);
  const allImagesThumbFlat = images.slice(ImagesLength);

  //4 サムネイルにindexを付ける
  for (let i = 0; i < ImagesLength; i++) {
    allImagesThumbFlat[i].index = i;
  }

  //5 サムネイルを行ごとにさらに配列分け
  const allImagesThumb = Array(5);
  for (let i = 0; i < 5; i++) {
    allImagesThumb[i] = [];
  }
  const rowLength = 90;//後にno451~500をスキャンしたら 90 → 100 に書き換える
  for (let i = 0, j = 0; i < ImagesLength; i++) {
    if (i !== 0 && i % rowLength === 0) j++
    allImagesThumb[j].push(allImagesThumbFlat[i])
  }




  /**
   * モダール用
   */
  const MaxPicSize = 280;
  const [isShow, setIsShow] = useState(false);
  const [picNum, setPicNum] = useState(Math.round(rowLength / 2)); //後にno451~500をスキャンしたら 22 → 25 に書き換える
  const [picSize, setPicSize] = useState(0.5);
  const [slideLeft, setSlideLeft] = useState(picNum % rowLength * picSize * MaxPicSize + winSize.width / 2);
  const [isFullscreen, setIsFullscreen] = useState(false);


  function handleModal(event, index) {
    event.stopPropagation();
    if (typeof index == "number") {
      setPicNum(index);
    }
    setIsShow(!isShow);
  }

  function handleModalKey(event, index) {
    if (event.keyCode === 13) { //Enter key
      if (typeof index == "number") {
        setPicNum(index);
      }
      setIsShow(true);
    }
  }

  function clickModal(event) {
    event.stopPropagation();
    const modal = document.getElementById("modalContent");
    const clientRect = modal.getBoundingClientRect();

    if (event.clientY < clientRect.top + clientRect.height * 0.23) {
      minusCol();
    } else if (event.clientY > clientRect.top + clientRect.height * 0.77) {
      plusCol();
    } else if (event.clientX > clientRect.left + clientRect.width * 0.5) {
      plusPicNum();
    } else {
      minusPicNum();
    }
  }


  function plusPicNum() {
    let result = (picNum + 1) % ImagesLength
    setPicNum(result);
    result % rowLength === 0 ? fixSlide(0) : fixSlideBySumbnailMoveRight();
  }

  function minusPicNum() {
    let isMinus = (picNum % rowLength) - 1 < 0;
    setPicNum(picNum => picNum - 1 < 0 ? ImagesLength - 1 : picNum - 1);
    isMinus ? fixSlide(Math.round(MaxPicSize * picSize) * rowLength - winSize.width) : fixSlideBySumbnailMoveLeft();
  }

  function plusCol() {
    setPicNum(picNum => picNum + rowLength >= ImagesLength ? picNum + rowLength - ImagesLength : picNum + rowLength);
  }

  function minusCol() {
    setPicNum(picNum => picNum - rowLength < 0 ? ImagesLength - rowLength + picNum : picNum - rowLength);
  }




  //キーボード操作でモダール画像チェンジ
  useEffect(() => {
    //関数コピペ
    function plusPicNum() {
      let result = (picNum + 1) % ImagesLength
      setPicNum(result);
      result % rowLength === 0 ? fixSlide(0) : fixSlideBySumbnailMoveRight();
    }

    function minusPicNum() {
      let isMinus = (picNum % rowLength) - 1 < 0;
      setPicNum(picNum => picNum - 1 < 0 ? ImagesLength - 1 : picNum - 1);
      isMinus ? fixSlide(Math.round(MaxPicSize * picSize) * rowLength - winSize.width) : fixSlideBySumbnailMoveLeft();
    }

    function plusCol() {
      setPicNum(picNum => picNum + rowLength >= ImagesLength ? picNum + rowLength - ImagesLength : picNum + rowLength);
    }

    function minusCol() {
      setPicNum(picNum => picNum - rowLength < 0 ? ImagesLength - rowLength + picNum : picNum - rowLength);
    }

    function zoomIn() {
      let oldSize = picSize;
      let result = picSize * 1.1 > 1 ? 1 : picSize * 1.1;
      setPicSize(result);
      //画面中央でズームさせる
      let oldWindowMidth = slideLeft + winSize.width / 2;
      let newSlideLeft = oldWindowMidth * result / oldSize - winSize.width / 2;
      fixSlide(newSlideLeft)
    }

    function zoomOut() {
      let oldSize = picSize;
      let result = picSize * 0.9 < 0.1 ? 0.1 : picSize * 0.9
      setPicSize(result);
      //画面中央でズームさせる
      let oldWindowMidth = slideLeft + winSize.width / 2;
      let newSlideLeft = oldWindowMidth * result / oldSize - winSize.width / 2;
      fixSlide(newSlideLeft)
    }

    function toLeft() {
      fixSlide(slideLeft - MaxPicSize * picSize)
    }

    function toRight() {
      fixSlide(slideLeft + MaxPicSize * picSize)
    }

    function fixSlide(slide) {
      let result = slide;
      let curSize = MaxPicSize * picSize;
      if (result > curSize * rowLength - winSize.width) result = curSize * rowLength - winSize.width;
      if (result < 0) result = 0;
      setSlideLeft(Math.round(result / curSize) * curSize);
    }

    function fixSlideBySumbnailMoveLeft() {
      let curSize = MaxPicSize * picSize
      let curSumbX = picNum % rowLength * curSize;
      if (curSumbX - slideLeft < curSize) toLeft();
    }

    function fixSlideBySumbnailMoveRight() {
      let curSize = MaxPicSize * picSize
      let curSumbX = picNum % rowLength * curSize;
      if (curSumbX - slideLeft > winSize.width - curSize * 2) toRight();
    }
    //関数コピペここまで

    function handlePicNum(event) {
      if (event.key === "ArrowRight") {
        plusPicNum();
      }
      else if (event.key === "ArrowLeft") {
        event.preventDefault()
        minusPicNum();
      }
      else if (event.key === "ArrowUp") {
        event.preventDefault()
        minusCol();
      }
      else if (event.key === "ArrowDown") {
        event.preventDefault()
        plusCol();
      }

      //Esc keyでモダール解除
      if (event.keyCode === 27) {
        setIsShow(false);
      }

      //キーボード操作でサムネイル全体画像移動
      if (event.shiftKey && event.key === "ArrowRight") { //16 = shift
        toRight();
      }
      if (event.shiftKey && event.key === "ArrowLeft") {
        toLeft();
      }
      if (event.keyCode === 73) { //73 = i
        zoomIn();
      }
      if (event.keyCode === 79) { //79 = o
        zoomOut();
      }

      /*
      if (event.keyCode === 70) { //70 = f TEST!!
        changeFullScreen();
      }
      */

      //画像選択にfocusをシンクロさせる
      if (isShow) document.getElementById(`pic${String(picNum)}`).focus();
    }


    //for touchscreen
    let handleTouchStart, handleTouchMove, handleTouchEnd;
    if (isTouch) {
      let startX;
      let moveX;
      let dist = 150;
      let isStart = false;

      handleTouchStart = e => {
        //e.preventDefault();
        if (!isStart) {
          isStart = true;
          startX = e.touches[0].pageX;
        }
      }
      handleTouchMove = e => {
        //e.preventDefault();
        moveX = e.changedTouches[0].pageX;
      }
      handleTouchEnd = e => {
        if (isStart) {
          isStart = false;
          if (startX && moveX) {
            if (startX > moveX && startX > moveX + dist) { // 右から左にスワイプ
              //e.preventDefault()
              toRight();
            } else if (startX < moveX && startX + dist < moveX) { // 左から右にスワイプ
              //e.preventDefault()
              toLeft();
            }
            startX = null;
            moveX = null;
          }
        }

      }
    }

    //window.addEventListener("resize", () => fixSlide(slideLeft));//重すぎるので使わない
    window.addEventListener('keydown', handlePicNum);
    if (isTouch) {
      document.addEventListener('touchstart', handleTouchStart);
      document.addEventListener('touchmove', handleTouchMove);
      document.addEventListener('touchend', handleTouchEnd);
    }
    return () => {
      //window.removeEventListener('resize', () => fixSlide(slideLeft));//重すぎるので使わない
      window.removeEventListener('keydown', handlePicNum);
      if (isTouch) {
        document.removeEventListener('touchstart', handleTouchStart);
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('touchend', handleTouchEnd);
      }
    }
  }, [picNum, isShow, picSize, slideLeft, winSize, ImagesLength])


  /**
   * サムネイルサイズチェンジ
   */
  function zoomIn() {
    let oldSize = picSize;
    let result = picSize * 1.1 > 1 ? 1 : picSize * 1.1;
    setPicSize(result);
    //画面中央でズームさせる
    let oldWindowMidth = slideLeft + winSize.width / 2;
    let newSlideLeft = oldWindowMidth * result / oldSize - winSize.width / 2;
    fixSlide(newSlideLeft)
  }

  function zoomOut() {
    let oldSize = picSize;
    let result = picSize * 0.9 < 0.1 ? 0.1 : picSize * 0.9
    setPicSize(result);
    //画面中央でズームさせる
    let oldWindowMidth = slideLeft + winSize.width / 2;
    let newSlideLeft = oldWindowMidth * result / oldSize - winSize.width / 2;
    fixSlide(newSlideLeft)
  }

  function toLeft() {
    fixSlide(slideLeft - MaxPicSize * picSize)
  }

  function toRight() {
    fixSlide(slideLeft + MaxPicSize * picSize)
  }

  function fixSlide(slide) {
    let result = slide;
    let curSize = MaxPicSize * picSize;
    if (result > curSize * rowLength - winSize.width) result = curSize * rowLength - winSize.width;
    if (result < 0) result = 0;
    setSlideLeft(Math.round(result / curSize) * curSize);
  }

  function fixSlideBySumbnailMoveLeft() {
    let curSize = MaxPicSize * picSize
    let curSumbX = picNum % rowLength * curSize;
    if (curSumbX - slideLeft < curSize) toLeft();
  }

  function fixSlideBySumbnailMoveRight() {
    let curSize = MaxPicSize * picSize
    let curSumbX = picNum % rowLength * curSize;
    if (curSumbX - slideLeft > winSize.width - curSize * 2) toRight();
  }

  function changeFullScreen() {
    setIsFullscreen(!isFullscreen);
    if (!isFullscreen) setSlideLeft(0)
    else fixSlide(slideLeft + winSize.width / 2)
  }

  const modaleScale = 2;


  return <>
    <Seo
      title={lang === "en" ? "Connected Drawings" : "繋ぎ絵"}
      description={lang === "en" ?
        "An infinite huge drawing that grows forever made by connected drawings drawn by the technique of Automatism" :
        "オートマティスムの技法で描いたドローイングを前後左右に描き繋いでいくことで永遠に増殖していく不定形な巨大な絵"
      }
      lang={lang}
      image="https://www.drawinghell.com/ogp_images/seo_connected.jpg"
      pathname="/work/connected"
    />

    {/**
       * ページ＆サムネイル
       */}

    <Layout
      checked="worksParts"
      winSize={winSize}
    >
      <div
        className={article}
      >
        <div>
          <LanguageBar
            setLang={setLanguage}
            lang={lang}
          />
        </div>
        <div
          className={titleAndButts}
        >

          <h1
            className={eachTitle}
            style={{
              letterSpacing: `${lang === "en" ? "0.05rem" : "0.25rem"}`
            }}
          >
            {lang === "en" ? "Connected Drawings" : " 繋ぎ絵"}
          </h1>
          <p className={desc}>
            {lang === "en" ?
              `ink on paper, 270mm × 380mm × 500sheets (*lack of 50 images here), 2000-2002` :
              `インク、紙、270mm × 380mm × 500枚 (*うち50枚未スキャン)、2000-2002年`}
          </p>


          <div
            className={`${buttonsDiv} ${fullScreenButtDiv}`}
          >
            <button
              type="submit"
              className={`${button} ${fullScreenButt} `}
              onClick={() => changeFullScreen()}
              style={{
                color: `${isFullscreen ? "#eda921" : "#bbbbbb"}`
              }}
            >
              {lang === "en" ? "Full display" : "全表示"}
            </button>
          </div>

          <div
            className={buttonsDiv}
          >
            <span
              className={buttons}
              style={{
                margin: `25px ${5 + 70 * Math.min(winSize.width / 1200, 1) ** 3}px 20px`
              }}
            >
              <button
                type="submit"
                className={button}
                onClick={() => zoomOut()}
                style={{
                  width: `${40 + 20 * Math.min(winSize.width / 1200, 1)}px`,
                  margin: `0px ${2.5 + 10 * Math.min(winSize.width / 1200, 1)}px`,
                }}
                title="zoom out (o)"
              >−</button>
              <button
                type="submit"
                className={button}
                onClick={() => zoomIn()}
                style={{
                  width: `${40 + 20 * Math.min(winSize.width / 1200, 1)}px`,
                  margin: `0px ${2.5 + 10 * Math.min(winSize.width / 1200, 1)}px`,
                }}
                title="zoom in (i)"
              >+</button>

            </span>
            <span
              className={buttons}
              style={{
                margin: `25px ${5 + 70 * Math.min(winSize.width / 1200, 1) ** 3}px 20px`
              }}
            >
              <button
                type="submit"
                className={button}
                onClick={() => toLeft()}
                style={{
                  width: `${40 + 20 * Math.min(winSize.width / 1200, 1)}px`,
                  margin: `0px ${2.5 + 10 * Math.min(winSize.width / 1200, 1)}px`,
                }}
                title="slide to left(Shift + Arrow Left)"
              >←</button>
              <button
                type="submit"
                className={button}
                onClick={() => toRight()}
                style={{
                  width: `${40 + 20 * Math.min(winSize.width / 1200, 1)}px`,
                  margin: `0px ${2.5 + 10 * Math.min(winSize.width / 1200, 1)}px`,
                }}
                title="slide to right(Shift + Arrow Right)"
              >→</button>

            </span>
          </div>

        </div>
        <Article
          isLangBar="false"
          winSize={winSize}
          setLang={setLanguage}
          lang={props.lang}
          articleMaxWidth={isFullscreen ? MaxPicSize * picSize * rowLength : winSize.width}
          articleOverflow={isFullscreen ? "auto" : "hidden"}
        >

          <div
            className={sumbNails}
            style={{
              width: `${MaxPicSize * picSize * rowLength}px`,
              left: `${-1 * slideLeft}px`
            }}
          >
            {allImagesThumb.map(col => col.map((node) => (
              <div
                className={isShow && node.index === picNum ? `${sumbNail} ${sumbNailCur}` : sumbNail}
                onClick={event => handleModal(event, node.index)}
                onKeyDown={event => handleModalKey(event, node.index)}
                role="button"
                tabIndex={0}
                style={{
                  width: `${MaxPicSize * picSize}px`,
                }}
                key={node.base.split(".")[0]}
                id={"pic" + String(node.index)}
              >
                <GatsbyImage
                  image={node.childImageSharp.gatsbyImageData}
                  alt={node.base.split(".")[0]} />
              </div>
            )))}
          </div>

        </Article>
      </div>
    </Layout>

    {/**
       * モーダル
      */}
    <div
      className={
        isShow ? `${modalArea} ${isShowCSS}` : modalArea
      }
    >
      <div
        className={modalBg}
        onClick={event => handleModal(event)}
        onKeyDown={() => { }}
        role="button"
        tabIndex={0}
      >
        <div
          className={modalWrapper}
          style={{
            width:
              calcModalSize(winSize,
                allImagesThumbFlat[picNum].childImageSharp.gatsbyImageData.width,
                allImagesThumbFlat[picNum].childImageSharp.gatsbyImageData.width/allImagesThumbFlat[picNum].childImageSharp.gatsbyImageData.height,
                modaleScale * Math.min(Math.max(winSize.width / 640, 0.53), 1), 0.9) + "px"
          }}
        >

          <div className={imgHide} >
            <GatsbyImage
              image={allImages[picNum + 1 >= ImagesLength ? 0 : picNum + 1].childImageSharp.gatsbyImageData}
              alt={allImages[picNum + 1 >= ImagesLength ? 0 : picNum + 1].base.split(".")[0]} />
          </div>
          <div className={imgHide} >
            <GatsbyImage
              image={allImages[picNum - 1 < 0 ? ImagesLength - 1 : picNum - 1].childImageSharp.gatsbyImageData}
              alt={allImages[picNum - 1 < 0 ? ImagesLength - 1 : picNum - 1].base.split(".")[0]} />
          </div>
          <div className={imgHide} >
            <GatsbyImage
              image={allImages[picNum + rowLength >= ImagesLength ? picNum + rowLength - ImagesLength : picNum + rowLength].childImageSharp.gatsbyImageData}
              alt={allImages[picNum + rowLength >= ImagesLength ? picNum + rowLength - ImagesLength : picNum + rowLength].base.split(".")[0]} />
          </div>
          <div className={imgHide} >
            <GatsbyImage
              image={allImages[picNum - rowLength < 0 ? ImagesLength - rowLength : picNum - rowLength].childImageSharp.gatsbyImageData}
              alt={allImages[picNum - rowLength < 0 ? ImagesLength - rowLength : picNum - rowLength].base.split(".")[0]} />
          </div>

          <div
            className={
              isShow ? `${modalContentCSS} ${modalContentScale}` : modalContentCSS}
            onClick={event => clickModal(event)}
            onKeyDown={() => { }}
            role="button"
            tabIndex={0}
            id="modalContent"
          >
            <GatsbyImage
              image={allImages[picNum].childImageSharp.gatsbyImageData}
              alt={allImages[picNum].base.split(".")[0]} />
          </div>
        </div>
      </div>
      <span
        className={close}
        onClick={event => handleModal(event)}
        onKeyDown={() => { }}
        role="button"
        tabIndex={0}
      >
        ×
      </span>
    </div>
  </>;

}

export default Connected



