// extracted by mini-css-extract-plugin
export var article = "connected-module--article--FJtSO";
export var button = "connected-module--button--+PMU1";
export var buttons = "connected-module--buttons--3AxNQ";
export var buttonsDiv = "connected-module--buttonsDiv--RBdlJ";
export var close = "connected-module--close--DheXJ";
export var desc = "connected-module--desc--TW8rN";
export var eachTitle = "connected-module--eachTitle--x87LS";
export var fullScreenButt = "connected-module--fullScreenButt--OSdi2";
export var fullScreenButtDiv = "connected-module--fullScreenButtDiv--9TV3X";
export var imgHide = "connected-module--imgHide--7OsV4";
export var isShowCSS = "connected-module--isShowCSS--3Nw96";
export var modalArea = "connected-module--modalArea--J2aIR";
export var modalBg = "connected-module--modalBg--yimZk";
export var modalContent = "connected-module--modal-content--+gAMM";
export var modalContentCSS = "connected-module--modalContentCSS--FNel-";
export var modalContentScale = "connected-module--modalContentScale--bEuiP";
export var modalWrapper = "connected-module--modalWrapper--iRXO3";
export var sumbNail = "connected-module--sumbNail--ah9vI";
export var sumbNailCur = "connected-module--sumbNailCur--P76Pn";
export var sumbNails = "connected-module--sumbNails--PncO1";
export var titleAndButts = "connected-module--titleAndButts--9ZVaa";